<!--
 * @Author: your name
 * @Date: 2022-04-08 09:51:19
 * @LastEditTime: 2022-04-27 17:58:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\feet\index.vue
-->
<template>
  <div class="feet">
    <div class="feet-top">
      <div @click="showservice = true">服务条款</div>
      <div class="line"></div>
      <div @click=" show = true ">免责声明</div>
    </div>
    <p class="pone">版权所有: 河南云锐网络技术有限公司 ©2022</p>
    <!-- <p class="pone">河南云锐网络技术有限公司</p> -->
    <allservice :show="show" @query="qService"></allservice>
    <serviceBox :show="showservice" @query="query"></serviceBox>
  </div>
</template>
<script>
import serviceBox from "@/components/serviceBox/index.vue"
import allservice from "@/components/allservice/index.vue"
export default {
  data() {
    return {
      show:false,  // 是否展示免责声明
      showservice:false, // 是否展示服务条款
    }
  },
  components:{
    allservice,
    serviceBox
  },
  methods:{
    // 服务条款关闭
    query(){
      this.showservice = false
    },
    // 免责声明关闭
    qService(){
      this.show = false
    }
  }
}
</script>
<style lang="less" scoped>
.feet{
  max-width: 190px;
  margin:  0 auto;
  position: fixed;
  left: 0 ;
  right: 0;
  bottom: 5vh;
  .feet-top{
    display: flex;
    margin: 0 auto;
    max-width: 140px;
    justify-items: center;
    align-items: center;
    font-size: 14px;
    color: #666;
    justify-content: space-between;
    .line{
      background-color: #666666;
      height: 14px;
      width: 1px;
    }
  }
  .pone{
    color: #666;
    font-size: 14px;
    text-align: center;
    margin-top: 12px;
  }
}
</style>